import { toast } from "react-toastify";

export const useCustomToast = () => {
  const successToast = (successMsg ="") => {
    return toast.success(successMsg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const errorToast = (errorMsg ="") => {
    return toast.error(errorMsg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return {
    successToast,
    errorToast,
  };
};
