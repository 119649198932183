import { useCustomToast } from "./useToast";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DataType } from "../types";
import { UseMutationResult } from "react-query";

export const useFormDataAndApiMutateHandler = <T extends DataType, K = unknown>(
  validator: any,
  apiQueryHook: (options?: {}) => UseMutationResult<any, unknown, T, unknown>,
  msg?: string,
  errorMsg: string = "",
  next?: (res: K) => void
) => {
  const { successToast, errorToast } = useCustomToast();
  const {
    register,
    reset,
    trigger,
    handleSubmit,
    getValues,
    getFieldState,
    control,
    formState: { errors, isDirty },
  } = useForm<T>({
    resolver: yupResolver(validator),
  });
  const {
    mutate: sendInfo,
    isLoading,
    isError,
  } = apiQueryHook({
    onSuccess: (res: K) => {
      successToast(msg || "Success!!!");
      next && next(res);
    },
    onError: (err: any) => {
      errorToast(
        err?.message + errorMsg ||
          err?.message ||
          err?.response?.data?.error?.message ||
          "Try again!!!"
      );
    },
  });

  const onSubmit: SubmitHandler<T> = (data: T) => {
    sendInfo(data);
    reset();
  };

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    isLoading,
    sendInfo,
    getValues,
    getFieldState,
    trigger,
    control,
    isDirty,
    reset,
    isError,
  };
};
