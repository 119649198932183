import { HelmetProvider } from "react-helmet-async";
import Layout from "./Layout";
import { BookingProvider } from "./context/cart/bookingContext";
import { QueryClientProvider, QueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { EventProvider } from "./context/eventContext";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 1,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <>
      <HelmetProvider>
        <BookingProvider>
          <EventProvider>
            <QueryClientProvider client={queryClient}>
              <ToastContainer />
              <BrowserRouter>
                <Layout />
              </BrowserRouter>
            </QueryClientProvider>
          </EventProvider>
        </BookingProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
