import { NonAuthNavbar } from "../components/Navbar";
import styles from "../styles/styles";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import { useCustomMode } from "../Hooks/useCustomMode";
import { motion, AnimatePresence } from "framer-motion";
import { useContext } from "react";
import Login from "../components/Login/Login";
import SignUp from "../components/SignUp/SignUp";
import ChatUs from "../components/ChatUs";
import { Pages } from "../Routes";
import { EventValues } from "../context/eventContext";
import useScrollToTop from "../Hooks/useScrollToTop";

export default function Layout(): JSX.Element {
  useScrollToTop();
  const location = useLocation();
  const mode = useCustomMode();
  const { setShowModalHandler, setShowModal, showModal } =
    useContext(EventValues);
  return (
    <div className={mode === "true" ? "dark" : ""}>
      <div className="font-manrope relative">
        <NonAuthNavbar setShowModal={setShowModalHandler} />
        <div
          className={`${styles.boxWidth} min-h-screen dark:text-dimWhite text-textColor`}
        >
          <AnimatePresence onExitComplete={() => setShowModal("")}>
            <Pages key={location.key} />
          </AnimatePresence>
        </div>
        <Footer />
        <AnimatePresence mode="wait">
          {showModal === "login" ? (
            <motion.div
              exit={{ opacity: 0, scale: 0 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
              className={`overflow-y-scroll fixed py-16 sm:py-[initial] inset-0 bg-black/60 z-[100]`}
            >
              <Login setShowModal={setShowModalHandler} />
            </motion.div>
          ) : null}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {showModal === "signup" ? (
            <motion.div
              exit={{ opacity: 0, scale: 0 }}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
              className={`overflow-y-scroll py-16 sm:py-[initial] fixed inset-0 bg-black/60 z-[100]`}
            >
              <SignUp setShowModal={setShowModalHandler} />
            </motion.div>
          ) : null}
        </AnimatePresence>
        <ChatUs />
      </div>
    </div>
  );
}
