import { motion } from "framer-motion";

interface Props {
  [key: string]: any;
}
export default function Button({
  wrap,
  width,
  textColor,
  text,
  bgColor,
  LogoIcon,
  iconColor,
  click,
  active,
  border,
  borderColor,
  height,
  type,
  disabled,
}: Props): JSX.Element {
  return (
    <motion.button
      disabled={disabled && disabled}
      type={type}
      className={`${
        active === text ? bgColor : "bg-white/10"
      } ${wrap} flex items-center px-[1rem] py-[1rem] disabled:cursor-wait justify-center  rounded-[8px] outline-none h-[50px] ${height} ${border} ${
        active === text ? borderColor : "border-dimWhite"
      } ${width}  `}
      onClick={click && click}
      whileHover={{ scale: [1, 1.02, 1, 1.02, 1] }}
      transition={{ duration: 0.5 }}
    >
      {LogoIcon && (
        <div className={`${iconColor}`}>
          <LogoIcon />
        </div>
      )}
      <p
        className={`${
          active === text ? textColor : "text-white"
        } px-[0.5rem] text-[14px] font-semibold`}
      >
        {text}
      </p>
    </motion.button>
  );
}
