import { User } from "../types";
import { USER_AUTH_STORAGE } from "./apiUrls";

export const phoneRegExp =
  /^((\+[1-9][ \\-]*)|(\\([0-9]\\)[ \\-]*)|([0-9])[ \\-]*)*?[0-9]?[ \\-]*[0-9]?$/;

export const nameRegExp = /^[a-zA-Z-]+$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const isActive = (path: string) =>
  window.location.pathname.includes(path);

export const getUserDetailsFromStorage = () => {
  return JSON.parse(localStorage.getItem(USER_AUTH_STORAGE) as string) as User;
};
export const handleLogout = () => {
  localStorage.removeItem(USER_AUTH_STORAGE);
  window.location.href = "/";
};

export const isLoggedIn = () => {
  const user = getUserDetailsFromStorage();
  return user && user.jwt ? user.jwt : undefined;
};

export const generateReference = () => {
  return `${"CLI"}${
    Date.now().toString(36) + Math.random().toString(36).substring(2)
  }`.toUpperCase();
};
