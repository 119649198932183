import axios from "axios";
import { BASE_URL, orderPath } from "../utils/apiUrls";
import { IcreateBookingPayload } from "../types";
import { isLoggedIn } from "../utils/helper";

export const createOrder = async (payload: IcreateBookingPayload) => {
  const res = await axios.post(
    `${BASE_URL}${orderPath}`,
    { data: payload },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${isLoggedIn()}`,
      },
    }
  );
  return res.data;
};
