import { BASE_URL, loginPath, registerPath } from "../utils/apiUrls";
import axios from "axios";
import { IsignupPayload, IloginPayload, User } from "../types";

export const signUpUser = async (payload: IsignupPayload): Promise<User> => {
  const res = await axios.post(`${BASE_URL}${registerPath}`, payload);
  return res.data;
};

export const loginUser = async (payload: IloginPayload): Promise<User> => {
  const res = await axios.post(`${BASE_URL}${loginPath}`, payload);
  return res.data;
};
