import { CleanItLogo, Copyright } from "../../assests";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="bg-colorTwo">
      <div
        className={`${styles.boxWidth} ${styles.paddingX} ${styles.paddingY} `}
      >
        <div className="flex sm:flex-row gap-y-[1rem] flex-col justify-between mb-[4rem] text-dimWhite15">
          <div className="flex-1">
            <Link to="/">
              <div className="flex items-center w-[80px] h-[80px] sm:w-[100px] sm:h-[100px]">
                <img
                  alt=""
                  className="rounded-[4px] max-w-full max-h-full object-cover"
                  src={CleanItLogo}
                />
              </div>
            </Link>
          </div>

          <div className="flex-1">
            <h3 className={`${styles.heading3}   mb-[1rem] text-dimWhite`}>
              Services
            </h3>
            <ul>
              <li>Regular Cleaning</li>
              <li>Deep Cleaning</li>
              <li>Fumigation</li>
            </ul>
          </div>
          <div className="flex-1">
            <h3 className={`${styles.heading3} mb-[1rem] text-dimWhite`}>
              Become an Associate
            </h3>
            <ul>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/terms">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/policies">Policy</Link>
              </li>
            </ul>
          </div>
          <div className="flex-1 flex flex-col sm:items-end">
            <p className="sm:text-right">
              No.1, Anjorin lane, off Olukole street, Surulere, Lagos
            </p>
            <p className="sm:text-right">+234 706 867 8679</p>
            <p className="sm:text-right">info@cleanit.ng</p>
            <div></div>
          </div>
        </div>
        <hr className="my-[1rem] sm:w-[90%] mx-auto border-t-[0.5px] border-l-0 border-r-0 border-b-0 border-[#E1F6E9/50]" />
        <p className="text-dimWhite flex sm:px-[6rem] items-center gap-x-[0.5rem]">
          <img alt="" src={Copyright} />
          Copyright {new Date().getFullYear()}. Cleanit Inc.
        </p>
      </div>
    </div>
  );
}

export default Footer;
