export const enum ActionType {
  ADD_CLEANING_TYPE = "ADD_CLEANING_TYPE",
  ADD_APARTMENT_TYPE = "ADD_APARTMENT_TYPE",
  NO_OF_ROOMS = "NO_OF_ROOMS",
  FREQUENCY = "FREQUENCY",
  ADD_DATE = "ADD_DATE",
  ADD_TIME = "ADD_TIME",
  ADD_ROOMS_PRICE_LOOKUP_OBJECT = "ADD_ROOMS_PRICE_LOOKUP_OBJECT",
  ADD_FREQUENCY_LOOKUP_OBJECT = "ADD_FREQUENCY_LOOKUP_OBJECT",
}
