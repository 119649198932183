import axios from "axios";
import {
  BASE_URL,
  apartmentTypePath,
  associatePath,
  roomPricePath,
  serviceFrequencyPath,
  serviceStatePath,
  serviceTypePath,
} from "../utils/apiUrls";
import { IAssociatePayload } from "../types";

export const createAssociate = async (payload: IAssociatePayload) => {
  const data = new FormData();
  const { guarantor_identification, identification, ...others } = payload;

  data.append("files.guarantor_identification", guarantor_identification);
  data.append("files.identification", identification);
  data.append("data", JSON.stringify(others));

  const resp = await axios.post(`${BASE_URL}${associatePath}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return resp.data;
};

export const getStates = async () => {
  const res = await axios.get(`${BASE_URL}${serviceStatePath}`);
  return res.data.data;
};
export const getPricePerRoom = async () => {
  const res = await axios.get(`${BASE_URL}${roomPricePath}`);
  return res.data.data;
};
export const getServiceTypes = async () => {
  const res = await axios.get(`${BASE_URL}${serviceTypePath}`);
  return res.data.data;
};
export const getApartmentTypes = async () => {
  const res = await axios.get(`${BASE_URL}${apartmentTypePath}`);
  return res.data.data;
};
export const getServiceFrequency = async () => {
  const res = await axios.get(`${BASE_URL}${serviceFrequencyPath}`);
  return res.data.data;
};
