import { createContext } from "react";
import moment from "moment";
import { ApartmentType } from "../../enums/ApartmentType";
import { ServiceType } from "../../enums/ServiceType";
import { useContextStateAndAction } from "./useContextStateAndAction";
import { GObject, GObjectOfObject } from "../../types";

interface Props {
  children: React.ReactNode;
}
export type InitialState = {
  serviceType: ServiceType;
  apartmentType: ApartmentType;
  numberOfRooms: number | string;
  frequency: number | string;
  date: Date;
  time: string;
  lookUpObjectForNoOfRooms: GObjectOfObject | null;
  lookUpObjectForFrequency: GObject | null;
};

const initialState: InitialState = {
  serviceType: ServiceType.REGULAR,
  apartmentType: ApartmentType.FLAT,
  numberOfRooms: 1,
  frequency: 1,
  date: new Date(),
  time: moment(new Date().getTime()).format("hh:mm"),
  lookUpObjectForNoOfRooms: null,
  lookUpObjectForFrequency: null,
};

type BookingContextType = ReturnType<typeof useContextStateAndAction>;

export const bookingContext = createContext<BookingContextType>({
  updateFumigationService: () => {},
  updateDeepService: () => {},
  updateRegularService: () => {},
  updateDuplex: () => {},
  updateFlat: () => {},
  state: initialState,
  dispatch: () => {},
  updateDate: () => {},
  updateTime: () => {},
  updateFrequency: () => {},
  updateNumberOfRooms: () => {},
  updateLookupObjectForNoOfRooms: () => {},
  updateLookupObjectForFrequency: () => {},
});

export const BookingProvider = ({ children }: Props) => {
  return (
    <bookingContext.Provider value={useContextStateAndAction(initialState)}>
      {children}
    </bookingContext.Provider>
  );
};
