import { ActionType } from "../../enums/ActionType";
import { ApartmentType } from "../../enums/ApartmentType";
import { ServiceType } from "../../enums/ServiceType";
import { GObject, GObjectOfObject } from "../../types";
import { InitialState } from "./bookingContext";

type Action =
  | { type: ActionType.ADD_CLEANING_TYPE; payload: ServiceType }
  | { type: ActionType.ADD_APARTMENT_TYPE; payload: ApartmentType }
  | { type: ActionType.NO_OF_ROOMS; payload: number | string }
  | { type: ActionType.FREQUENCY; payload: number | string }
  | { type: ActionType.ADD_DATE; payload: Date }
  | { type: ActionType.ADD_TIME; payload: string }
  | { type: ActionType.ADD_ROOMS_PRICE_LOOKUP_OBJECT; payload: GObjectOfObject }
  | { type: ActionType.ADD_FREQUENCY_LOOKUP_OBJECT; payload: GObject };

export const reducer = (state: InitialState, action: Action) => {
  switch (action.type) {
    case ActionType.ADD_CLEANING_TYPE:
      return {
        ...state,
        serviceType: action.payload,
      };
    case ActionType.ADD_APARTMENT_TYPE:
      return {
        ...state,
        apartmentType: action.payload,
      };
    case ActionType.NO_OF_ROOMS:
      return {
        ...state,
        numberOfRooms: action.payload,
      };
    case ActionType.FREQUENCY:
      return {
        ...state,
        frequency: action.payload,
      };
    case ActionType.ADD_DATE:
      return {
        ...state,
        date: action.payload,
      };
    case ActionType.ADD_TIME:
      return {
        ...state,
        time: action.payload,
      };
    case ActionType.ADD_ROOMS_PRICE_LOOKUP_OBJECT:
      return {
        ...state,
        lookUpObjectForNoOfRooms: action.payload,
      };
    case ActionType.ADD_FREQUENCY_LOOKUP_OBJECT:
      return {
        ...state,
        lookUpObjectForFrequency: action.payload,
      };
    default:
      return state;
  }
};
