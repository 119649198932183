import React, { useCallback } from "react";
import { createContext, useState } from "react";
import { EventValuesContextType, ShowModal, mode } from "../types";

export const EventValues = createContext({} as EventValuesContextType);

export const EventProvider = ({ children }: { children: React.ReactNode }) => {
  const [mode, setMode] = useState<mode>("true");
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState<ShowModal>("");

  const toggleTheme = (mode: mode): void => {
    if (mode === "false") {
      setMode("true");
      localStorage.setItem("mode", "true");
    } else {
      setMode("false");
      localStorage.setItem("mode", "false");
    }
  };

  const setShowModalHandler = useCallback(
    (action: ShowModal) => setShowModal(action),
    []
  );

  return (
    <EventValues.Provider
      value={{
        screenSize,
        setScreenSize,
        toggleTheme,
        mode,
        setMode,
        setShowModalHandler,
        setShowModal,
        showModal,
      }}
    >
      {children}
    </EventValues.Provider>
  );
};
