import { whatsapp } from "../../assests";
import { motion } from "framer-motion";

export default function ChatUs() {
  return (
    <motion.div
      initial="hidden"
      whileHover="show"
      transition={{
        duration: 0.2,
        type: "tween",
      }}
      className="fixed bottom-4 right-4 w-[65px] h-[65px] sm:w-[80px] sm:h-[80px] z-[50]"
    >
      <a
        href={`http://wa.me/2347068678679?text=${encodeURI(
          "Hi, I want to have a chat with the support team"
        )}`}
      >
        <img src={whatsapp} alt="whatsapp icon" className="w-full h-full" />
      </a>
      <motion.p
        variants={{
          hidden: {
            opacity: 0,
          },
          show: {
            x: "-100%",
            y: "-100%",
            opacity: 1,
          },
        }}
        className="font-semibold absolute whitespace-nowrap top-[25%] rounded-[4px] cursor-pointer 
     text-white bg-green-600 px-2 py-1"
      >
        Chat Us
      </motion.p>
    </motion.div>
  );
}
