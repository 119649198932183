import { useMutation, useQuery } from "react-query";
import { createOrder } from "../../Services/orderApiRequest";
import { signUpUser, loginUser } from "../../Services/authApiRequest";
import {
  SIGNUP_QUERY_KEY,
  LOGIN_QUERY_KEY,
  CREATE_ORDER_QUERY_KEY,
} from "../../utils/queryKeys";
import {
  createAssociate,
  getApartmentTypes,
  getPricePerRoom,
  getServiceFrequency,
  getServiceTypes,
  getStates,
} from "../../Services/userApiRequest";
import { ASSOCIATE_QUERY_KEY } from "../../utils/queryKeys";

export const useSignUpUser = (options = {}) => {
  return useMutation(signUpUser, {
    mutationKey: SIGNUP_QUERY_KEY,
    ...options,
  });
};
export const useCreateOrder = (options = {}) => {
  return useMutation(createOrder, {
    mutationKey: CREATE_ORDER_QUERY_KEY,
    ...options,
  });
};

export const useLoginUser = (options = {}) => {
  return useMutation(loginUser, {
    mutationKey: LOGIN_QUERY_KEY,
    ...options,
  });
};
export const useCreateAssociate = (options = {}) => {
  return useMutation(createAssociate, {
    mutationKey: ASSOCIATE_QUERY_KEY,
    ...options,
  });
};
export const useGetStates = (options = {}) => {
  return useQuery("STATE_QUERY_KEY", getStates, {
    ...options,
  });
};
export const useGetPricesPerRoom = (options = {}) => {
  return useQuery("ROOM_PRICE_QUERY_KEY", getPricePerRoom, {
    ...options,
  });
};
export const useGetServiceTypes = (options = {}) => {
  return useQuery("SERVICE_PRICE_QUERY_KEY", getServiceTypes, {
    ...options,
  });
};
export const useGetApartmentTypes = (options = {}) => {
  return useQuery("APARTMENT_PRICE_QUERY_KEY", getApartmentTypes, {
    ...options,
  });
};
export const useGetServiceFrequency = (options = {}) => {
  return useQuery("SERVICE_FREQUENCY_QUERY_KEY", getServiceFrequency, {
    ...options,
  });
};
