import { SetStateAction, useState } from "react";
import Button from "../Button/index";
import { signupSchema } from "../../lib/yupConfig";
import { useRef } from "react";
import { useOnClickOutside } from "../../Hooks/useOnClickOutside";
import { useSignUpUser } from "../../Hooks/apiQueryRequestHooks/useQueryHooks";
import { IsignupPayload, User } from "../../types";
import { USER_AUTH_STORAGE } from "../../utils/apiUrls";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import { motion } from "framer-motion";
import { useFormDataAndApiMutateHandler } from "../../Hooks/useFormDataAndApiMutateHandler";

type ShowModal = "login" | "signup" | "";

type Props = {
  setShowModal(value: SetStateAction<ShowModal>): void;
};

export default function SignUp({ setShowModal }: Props) {
  const [password, setPassword] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, () => setShowModal((prev) => (prev = "")));
  const redirectUser = (res: User) => {
    localStorage.setItem(USER_AUTH_STORAGE, JSON.stringify(res));
    setTimeout(() => {
      setShowModal((prev) => (prev = ""));
    }, 500);
  };
  const { register, onSubmit, handleSubmit, errors, isLoading } =
    useFormDataAndApiMutateHandler<IsignupPayload, User>(
      signupSchema,
      useSignUpUser,
      "User Signed Up Successfully",
      "",
      redirectUser
    );
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="sm:flex items-center justify-center h-full"
    >
      <motion.div
        initial={{ opacity: 0, y: "-100vh" }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        ref={ref}
        className="px-4 sm:px-8 py-[5rem] bg-white text-[#000d49]
    rounded-[4px] mx-auto max-w-[716px] w-[95%]"
      >
        <div className="flex flex-col gap-4 sm:w-[85%] mx-auto ">
          <h4 className="text-[24px] leading-[27px] font-[700] mb-8">
            Sign Up
          </h4>
          <div className="flex flex-col sm:flex-row gap-4 ">
            <div className="flex flex-col  w-full">
              <label
                className="mb-[0.5rem] text-[14px] font-[700] text-[#000d49]"
                htmlFor="first_name"
              >
                First Name*
              </label>
              <input
                className="input max-w-full"
                placeholder="First Name"
                id="first_name"
                {...register("first_name")}
              />
              {errors?.first_name && (
                <p className="text-red-800">{errors.first_name.message}</p>
              )}
            </div>
            <div className="flex flex-col  w-full">
              <label
                className="mb-[0.5rem] text-[14px] font-[700] text-[#000d49]"
                htmlFor="last_name"
              >
                Last Name*
              </label>
              <input
                className="input max-w-full"
                placeholder="Last Name"
                id="last_name"
                {...register("last_name")}
              />
              {errors?.last_name && (
                <p className="text-red-800">{errors.last_name.message}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 w-full">
            <div className="flex flex-col w-full">
              <label
                className="mb-[0.5rem] text-[14px] font-[700] text-[#000d49]"
                htmlFor="email"
              >
                Email*
              </label>
              <input
                className="input max-w-full"
                placeholder="Email"
                id="email"
                {...register("email")}
              />
              {errors?.email && (
                <p className="text-red-800">{errors.email.message}</p>
              )}
            </div>
            <div className="flex flex-col w-full">
              <label
                className="mb-[0.5rem] text-[14px] font-[700] text-[#000d49]"
                htmlFor="password"
              >
                Password*
              </label>
              <div className="relative">
                <input
                  className="input max-w-full"
                  placeholder="Password"
                  id="password"
                  {...register("password")}
                  type={!password ? "text" : "password"}
                />
                <div
                  className="absolute right-2 top-2 cursor-pointer text-black"
                  onClick={() => setPassword((prev) => !prev)}
                >
                  {!password ? (
                    <VisibilityRoundedIcon />
                  ) : (
                    <VisibilityOffRoundedIcon />
                  )}
                </div>
              </div>

              {errors?.password && (
                <p className="text-red-800">{errors.password.message}</p>
              )}
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 w-full">
            <div className="flex flex-col w-full">
              <label
                className="mb-[0.5rem] text-[14px] font-[700] text-[#000d49]"
                htmlFor="username"
              >
                Username*
              </label>
              <input
                className="input max-w-full"
                placeholder="Username"
                id="username"
                {...register("username")}
              />
              {errors?.username && (
                <p className="text-red-800">{errors.username.message}</p>
              )}
            </div>
            <div className="flex flex-col w-full">
              <label
                className="mb-[0.5rem] text-[14px] font-[700] text-[#000d49]"
                htmlFor="phone"
              >
                Phone Number*
              </label>
              <input
                className="input max-w-full"
                placeholder="Phone Number"
                id="phone"
                {...register("phone")}
              />
              {errors?.phone && (
                <p className="text-red-800">{errors.phone.message}</p>
              )}
            </div>
          </div>
          <div className="">
            <p className="text-black text-[14px] leading-[19px] font-[700]">
              Already registered?
              <span
                className="ml-2 text-secondary underline cursor-pointer"
                onClick={() => {
                  setShowModal("login");
                }}
              >
                Log In
              </span>
            </p>
          </div>
          <div className="mt-[42px]">
            <Button
              type="submit"
              active={"Sign Up"}
              width="w-full"
              textColor="text-white"
              text={
                isLoading ? (
                  <div
                    className={`w-[20px] h-[20px] border-[3px] border-[#000d49] border-b-[transparent]
               rounded-[50%] rotation flex mx-auto`}
                  ></div>
                ) : (
                  "Sign Up"
                )
              }
              bgColor="bg-[#000d49]"
              wrap="whitespace-nowrap"
              border="border-[1px]"
              borderColor="border-[#000d49]"
            />
          </div>
        </div>
      </motion.div>
    </form>
  );
}
