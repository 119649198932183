import { CleanItLogo, close, menu } from "../../assests";
import styles from "../../styles/styles";
import Button from "../Button";
import { Link, NavLink } from "react-router-dom";
import { useState, useRef } from "react";
import { EventValues } from "../../context/eventContext";
import { useContext } from "react";
import { useOnClickOutside } from "../../Hooks/useOnClickOutside";
import { getUserDetailsFromStorage, handleLogout } from "../../utils/helper";
import { useCustomMedia } from "../../Hooks/useCustomMedia";
import { useCustomScroll } from "../../Hooks/useCustomScroll";
import { motion } from "framer-motion";
import {
  HomeRounded,
  PaymentRounded,
  CleaningServicesRounded,
  HelpRounded,
  HandshakeRounded,
} from "@mui/icons-material";

type Props = {
  [key: string]: any;
};

export default function NonAuthNavbar({ setShowModal }: Props): JSX.Element {
  const animation = useCustomScroll();
  const { screenSize } = useCustomMedia();
  const [toggle, setToggle] = useState(false);
  const { mode } = useContext(EventValues);
  const closeRef = useRef<HTMLDivElement>(null);
  const user = getUserDetailsFromStorage();
  useOnClickOutside(closeRef, () => setToggle((prev) => (prev = false)));

  const activeLink =
    "text-[13px] leading-[21px] border border-mainBlueDarkShade p-[8px] rounded-[16px] flex gap-x-4 items-center whitespace-nowrap";
  const normalLink =
    "text-[13px] leading-[21px] flex gap-x-4 items-center whitespace-nowrap border border-[transparent] hover:border-mainBlueDarkShade p-[8px] rounded-[16px] text-mainBlueDarkShade transition duration-500 ease-in-out";

  return (
    <motion.div
      initial={screenSize > 768 ? { y: "-100%" } : { y: "0" }}
      animate={animation}
      className={`sm:h-[100px] h-[60px] bg-dimWhite/40 top-0 left-0 right-0 fixed z-[100] filterClass`}
    >
      <div className={`${styles.paddingX} h-full`}>
        <div className={`h-full`}>
          <div className={`h-full flex justify-between gap-x-4 items-center`}>
            <Link to="/" className="flex-1">
              <div className="flex items-center sm:w-[100px] sm:h-[100px] w-[60px] h-[60px]">
                <img
                  alt=""
                  className="rounded-[4px] max-w-full max-h-full object-cover"
                  src={CleanItLogo}
                />
              </div>
            </Link>
            <nav className={`sm:flex hidden ${styles.flexCenter} flex-1`}>
              <div className="flex gap-x-[1.5rem]">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? activeLink : normalLink
                  }
                >
                  <div className="hidden md:block text-blueShade35">
                    <HomeRounded />
                  </div>
                  Home
                </NavLink>

                <div className="flex items-center ">
                  <NavLink
                    to="/prices"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="hidden md:block text-blueShade35">
                      <PaymentRounded />
                    </div>
                    Prices
                  </NavLink>
                </div>
                <div className="flex items-center ">
                  <NavLink
                    to="/services"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="hidden md:block text-blueShade35">
                      <CleaningServicesRounded />
                    </div>
                    Services
                  </NavLink>
                </div>
                <div className="flex items-center ">
                  <NavLink
                    to="/associate"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="hidden md:block text-blueShade35">
                      <HandshakeRounded />
                    </div>
                    Become an Associate
                  </NavLink>
                </div>
                <div className="flex items-center ">
                  <NavLink
                    to="FAQ"
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    <div className="hidden md:block text-blueShade35">
                      <HelpRounded />
                    </div>
                    FAQs
                  </NavLink>
                </div>
              </div>
            </nav>
            <div
              className={`items-center justify-end flex gap-x-[1rem] flex-1`}
            >
              {user && (
                <div className={`md:flex hidden`}>
                  <Button
                    active={"LOG OUT"}
                    wrap="whitespace-nowrap"
                    width="w-[140px]"
                    text="LOG OUT"
                    border="border-[1px]"
                    bgColor="bg-transparent"
                    borderColor="border-white"
                    textColor="text-mainBlueDarkShade"
                    click={() => {
                      handleLogout();
                    }}
                  />
                </div>
              )}
              {!user && (
                <div className="lg:flex hidden">
                  <Button
                    active={"LOG IN"}
                    wrap="whitespace-nowrap"
                    width="w-[140px]"
                    text="LOG IN"
                    border="border-[1px]"
                    bgColor="bg-transparent"
                    borderColor="border-white"
                    textColor="text-mainBlueDarkShade"
                    click={() => {
                      setShowModal("login");
                    }}
                  />
                </div>
              )}
              <Link to="prices" className="lg:flex hidden">
                <Button
                  active={"BOOK NOW"}
                  wrap="whitespace-nowrap"
                  width="w-[140px]"
                  textColor="text-dimWhite"
                  text="BOOK NOW"
                  bgColor="bg-mainBlueDarkShade"
                  border="border-[1px]"
                  borderColor="bg-mainBlueDarkShade"
                />
              </Link>
            </div>

            <div
              ref={closeRef}
              className="sm:hidden flex flex-1 z-[10] justify-end items-center"
            >
              <img
                src={toggle ? close : menu}
                alt="menu"
                className="w-[28px] h-[28px] object-contain"
                onClick={() => setToggle((prev) => !prev)}
              />
              {toggle && (
                <div
                  className={` p-6 ${
                    mode === "true" ? "bg-colorTwo" : null
                  } absolute top-20 right-0 mx-4 my-2 min-w-[150px] rounded-xl sidebar`}
                >
                  <ul
                    className={`text-white flex justify-end items-start flex-1 flex-col gap-[1rem] text-[16px] font-semibold`}
                  >
                    <li onClick={() => setToggle((prev) => !prev)}>
                      <Link
                        to="/"
                        className={`${styles.flexCenter}  gap-x-[0.5rem]`}
                      >
                        Home
                      </Link>
                    </li>
                    <li onClick={() => setToggle((prev) => !prev)}>
                      <Link
                        to="/prices"
                        className={`${styles.flexCenter}  gap-x-[0.5rem]`}
                      >
                        Prices
                      </Link>
                    </li>
                    <li onClick={() => setToggle((prev) => !prev)}>
                      <Link
                        to="/services"
                        className={`${styles.flexCenter}  gap-x-[0.5rem]`}
                      >
                        Services
                      </Link>
                    </li>
                    <li onClick={() => setToggle((prev) => !prev)}>
                      <Link
                        to="/associate"
                        className={`${styles.flexCenter}  gap-x-[0.5rem]`}
                      >
                        Become an Associate
                      </Link>
                    </li>
                    <li onClick={() => setToggle((prev) => !prev)}>
                      <Link
                        to="/FAQ"
                        className={`${styles.flexCenter}  gap-x-[0.5rem]`}
                      >
                        FAQs
                      </Link>
                    </li>

                    {!user && (
                      <Button
                        width="w-[120px]"
                        active={"SIGN UP"}
                        textColor="text-mainBlueShade5"
                        text="SIGN UP"
                        bgColor="bg-mainBlueShade5/10"
                        wrap="whitespace-nowrap"
                        border="border-[1px]"
                        borderColor="border-mainBlueShade5"
                        click={() => {
                          setShowModal("signup");
                          setToggle((prev) => !prev);
                        }}
                      />
                    )}
                    {user && (
                      <Button
                        width="w-[120px]"
                        active={"LOG OUT"}
                        textColor="text-mainBlueShade5"
                        text="LOG OUT"
                        bgColor="bg-mainBlueShade5/10"
                        wrap="whitespace-nowrap"
                        border="border-[1px]"
                        borderColor="border-mainBlueShade5"
                        click={() => {
                          handleLogout();
                          setToggle((prev) => !prev);
                        }}
                      />
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
