import * as yup from "yup";

export const signupSchema = yup.object({
  first_name: yup.string().required("Please enter first name"),
  last_name: yup.string().required("Please enter last name"),
  phone: yup.string().required("Please enter phone"),
  password: yup.string().required("Please enter password"),
  email: yup
    .string()
    .email("Please enter email")
    .required("Please enter email"),
  username: yup.string().required("Please enter username"),
});
export const checkoutSchema = yup.object({
  first_name: yup.string().required("Please enter first name"),
  last_name: yup.string().required("Please enter last name"),
  phone: yup.string().required("Please enter phone"),
  email: yup
    .string()
    .email("Please enter email")
    .required("Please enter email"),
  address: yup.string().required("Please enter address"),
  state: yup.string().required("Please select state"),
});
export const associateSchema = yup.object({
  first_name: yup.string().required("Please enter first name"),
  last_name: yup.string().required("Please enter last name"),
  phone: yup.string().required("Please enter phone"),
  email: yup
    .string()
    .email("Please enter email")
    .required("Please enter email"),
  address: yup.string().required("Please enter address"),
  city: yup.string().required("Please enter city"),
  state: yup.string().required("Please enter state"),
  bank_name: yup.string().required("Please enter bank name"),
  account_name: yup.string().required("Please enter account name"),
  account_number: yup.string().required("Please enter account number"),
  bvn: yup.string().required("Please enter bvn"),
  identification: yup
    .mixed()
    .test("required", "Please upload identity", (value) => {
      return value && value;
    }),
  guarantor_first_name: yup.string().required("Please enter first name"),
  guarantor_last_name: yup.string().required("Please enter last name"),
  guarantor_phone: yup.string().required("Please enter phone"),
  guarantor_email: yup
    .string()
    .email("Please enter email")
    .required("Please enter email"),
  guarantor_address: yup.string().required("Please enter address"),
  guarantor_identification: yup
    .mixed()
    .test("required", "Please upload identity", (value) => {
      return value && value;
    }),
});
export const loginSchema = yup.object({
  identifier: yup.string().required("Please enter email"),
  password: yup.string().required("Please enter password"),
});
