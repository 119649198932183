import { lazy } from "react";
import { WithSuspense } from "../components/Suspense/WithSuspense";
import { Navigate, useRoutes } from "react-router-dom";

export type AppRoute = {
  path: string;
  element: React.ReactNode;
};

const Checkout = WithSuspense(lazy(() => import("../Pages/Checkout.Page")));
const LandingPage = WithSuspense(lazy(() => import("../Pages/Landing.Page")));
const FAQ = WithSuspense(lazy(() => import("../Pages/FAQ")));
const Associate = WithSuspense(lazy(() => import("../Pages/Associate")));
const Services = WithSuspense(lazy(() => import("../Pages/Services")));
const Prices = WithSuspense(lazy(() => import("../Pages/prices")));
const TermsPoliciesAndAbout = WithSuspense(
  lazy(() => import("../Pages/TermsPoliciesAndAbout"))
);

export const Pages = () => {
  const RoutePath: AppRoute[] = [
    { path: "/", element: <LandingPage /> },
    { path: "/checkout", element: <Checkout /> },
    { path: "/associate", element: <Associate /> },
    { path: "/FAQ", element: <FAQ /> },
    { path: "/terms", element: <TermsPoliciesAndAbout /> },
    { path: "/policies", element: <TermsPoliciesAndAbout /> },
    { path: "/about", element: <TermsPoliciesAndAbout /> },
    { path: "/services", element: <Services /> },
    { path: "/prices", element: <Prices /> },
    { path: "*", element: <Navigate to="/" /> },
  ];
  const routes = useRoutes(RoutePath);
  return routes;
};
