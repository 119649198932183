export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_REST_API_ENDPOINT
    : process.env.REACT_APP_LOCAL_REST_API_ENDPOINT;
export const publicKey =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PAYSTACK_PUBLIC_KEY
    : process.env.REACT_APP_PAYSTACK_TEST_PUBLIC_KEY;
export const USER_AUTH_STORAGE = "user_auth";
export const registerPath = "/auth/local/register";
export const orderPath = "/orders";
export const associatePath = "/associates";
export const loginPath = "/auth/local";
export const serviceStatePath = "/service-state";
export const roomPricePath = "/rooms-prices";
export const serviceTypePath = "/service-type";
export const apartmentTypePath = "/apartment-type";
export const serviceFrequencyPath = "/service-frequency";
