import { useCallback, useReducer } from "react";
import { InitialState } from "./bookingContext";
import { reducer } from "./reducer";
import { ActionType } from "../../enums/ActionType";
import { ApartmentType } from "../../enums/ApartmentType";
import { ServiceType } from "../../enums/ServiceType";
import { GObject, GObjectOfObject } from "../../types";

export const useContextStateAndAction = (initialState: InitialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const updateFlat = useCallback(() => {
    dispatch({
      type: ActionType.ADD_APARTMENT_TYPE,
      payload: ApartmentType.FLAT,
    });
    dispatch({
      type: ActionType.NO_OF_ROOMS,
      payload: 1,
    });
  }, []);
  const updateDuplex = useCallback(() => {
    dispatch({
      type: ActionType.ADD_APARTMENT_TYPE,
      payload: ApartmentType.DUPLEX,
    });
    dispatch({
      type: ActionType.NO_OF_ROOMS,
      payload: 1,
    });
  }, []);
  const updateRegularService = useCallback(() => {
    dispatch({
      type: ActionType.ADD_CLEANING_TYPE,
      payload: ServiceType.REGULAR,
    });
    dispatch({
      type: ActionType.NO_OF_ROOMS,
      payload: 1,
    });
  }, []);
  const updateDeepService = useCallback(() => {
    dispatch({
      type: ActionType.ADD_CLEANING_TYPE,
      payload: ServiceType.DEEP,
    });
    dispatch({
      type: ActionType.NO_OF_ROOMS,
      payload: 1,
    });
  }, []);
  const updateFumigationService = useCallback(() => {
    dispatch({
      type: ActionType.ADD_CLEANING_TYPE,
      payload: ServiceType.FUMIGATION,
    });
    dispatch({
      type: ActionType.NO_OF_ROOMS,
      payload: 1,
    });
  }, []);
  const updateDate = useCallback((date: Date) => {
    dispatch({ type: ActionType.ADD_DATE, payload: date });
  }, []);
  const updateTime = useCallback((time: string) => {
    dispatch({ type: ActionType.ADD_TIME, payload: time });
  }, []);
  const updateFrequency = useCallback((frequency: number | string) => {
    dispatch({
      type: ActionType.FREQUENCY,
      payload: frequency,
    });
  }, []);
  const updateNumberOfRooms = useCallback((numberOfRooms: number | string) => {
    dispatch({
      type: ActionType.NO_OF_ROOMS,
      payload: numberOfRooms,
    });
  }, []);
  const updateLookupObjectForNoOfRooms = useCallback(
    (data: GObjectOfObject) => {
      dispatch({
        type: ActionType.ADD_ROOMS_PRICE_LOOKUP_OBJECT,
        payload: data,
      });
    },
    []
  );
  const updateLookupObjectForFrequency = useCallback((data: GObject) => {
    dispatch({
      type: ActionType.ADD_FREQUENCY_LOOKUP_OBJECT,
      payload: data,
    });
  }, []);

  return {
    updateFumigationService,
    updateDeepService,
    updateRegularService,
    updateDuplex,
    updateFlat,
    state,
    dispatch,
    updateDate,
    updateTime,
    updateFrequency,
    updateNumberOfRooms,
    updateLookupObjectForNoOfRooms,
    updateLookupObjectForFrequency,
  };
};
